<template>
    <div class="dataCenter">
        <el-tabs
                v-model="activeName"
                type="card"
                class="education-content tabs-full"
                @tab-click="init">
            <el-tab-pane label="得分榜单" name="scoreList">
                <DataCenterScoreList/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import DataCenterScoreList from "./../../components/admin/DataCenterScoreList.vue";

    export default {
        name: "dataCenter",
        components: {
            DataCenterScoreList,
        },
        data() {
            return {
                activeName: "scoreList",
            };
        },
        methods: {
            init() {

            },
        },
    };
</script>

<style scoped lang="scss">
    .dataCenter {
        height: 100%;
        display: flex;
        flex-direction: column;

        .education-content {
            flex: 1;
            height: 1%;
        }
    }
</style>